// src/SignupForm.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../utils/auth"; // Import the login function

const LoginPage = ({ setPageType }) => {
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const handleLogin = async (event) => {

    event.preventDefault(); // Prevent default form submission behavior
    const credentials = { email, password };
    try {
      const token = await login(credentials);
      if (token) {
        navigate("/dashboard");
      } else {
        console.error("Login failed");
        // Optionally show an error message to the user
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };
  const isFormValid = email !== '' && password !== '';

  return (
    <section className="signUpMain">
      <div className="container">
        <div className="signUpBox loginForm">
          <div className="signUpBoxHeader">
            <h3>Welcome Back</h3>
            <p>We are glad to see you back with us</p>
          </div>
          <div className="signUpForm">
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email" 
                  name="usermail"
                  placeholder="Email Address"
                  required
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="remberForgot d-flex justify-content-lg-between">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    Remember Me
                  </label>
                </div>
                <div className="remberForgottext">
                  <a
                    href="#"
                    onClick={(e) => {
                      setPageType("forgotpasswordpage");
                    }}
                  >
                    Forgot Password
                  </a>
                </div>
              </div>
              <button type="submit" className="primary_btn">
                Login
              </button>
              <div className="buttonBtmText">
                <p className="text-center">
                  New to Crypto Labs? <a href="#" onClick={() => navigate("/register")}>Register Now</a>
                </p>
                <p className="text-center">
                  Trouble registering your account? <a href="#">Get help</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
