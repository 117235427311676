// src/utils/auth.js
export const getToken = () => {
  // Retrieve the token from local storage
  const token = localStorage.getItem("token");
  // Optional: Add error handling or default value
  if (!token) {
    console.warn("No Token Found in Local Storage");
    return null;
  }

  return token;
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("clabs_access_token");
  if (!token) {
    return false;
  }
  // Add token validation logic here
  try {
    // Example validation logic: decode and check token (if it's a JWT, for example)
    const isValid = validateToken(token); // Replace this with actual validation logic
    return isValid;
  } catch (error) {
    console.log("Token validation failed:", error);
    console.error("Token validation failed:", error);
    return false;
  }
};

const validateToken = (token) => {
  // Add your token validation logic here
  // This is a placeholder function and should be replaced with actual validation logic
  // For example, if using JWT, you might decode it and check its expiration
  if (typeof token !== "string" || token.length < 10) {
    // Example basic check
    throw new Error("Invalid token format");
  }
  return true;
};

export const login = async (credentials) => {
  console.log("loggin in")
  const url = `http://127.0.0.1:8000/account/login`;
  
  try {
    console.log(JSON.stringify(credentials))
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });
    console.log(response)
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const result = await response.json();
    console.log(`Logged in successfully`, result);
    localStorage.setItem("token", result.token);
    const token = localStorage.getItem("token");
    if (!token) {
      console.warn("Token not found in local storage");
      return null;
    }

    return token;
  } catch (error) {
    console.error(`Error logging in:`, error);
  }
  // Retrieve the token from local storage
};
