import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import optImage from "../images/opt-dash-img.png";
import howitwork from "../images/how-it-w.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Ecommerce = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center personal_Banner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                Crypto for <span className="orange_gredient_color_text">Ecommerce</span>{" "} 
                </h1>
                <p>
                Integrate CryptoLabs' plugin to easily accept crypto payments online, with options to receive funds in crypto or direct bank transfers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section  className="optimised_trading key_features_sec">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Key Features of Our eCommerce  <span class="orange_gredient_color_text">  Payment </span>  Solution</h2>
            <p>In the digital age, offering diverse payment options is crucial for the success of any online business. Our eCommerce payment solution enables online businesses to accept cryptocurrency payments seamlessly and securely. By integrating our cutting-edge crypto payment gateway, you can cater to a broader customer base, enhance transaction security, and stay ahead of the competition in the ever-evolving eCommerce landscape.</p>
           </div>  
           <div className="row">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Wide Cryptocurrency Support</h4>   
                  <p>Accept a variety of cryptocurrencies, including Bitcoin, Ethereum, Litecoin, and more, meeting the preferences of a diverse customer base.</p>
                  <img src={optImage} alt="" />
                </div>      
              </div>
              <div className="col-md-6">
                <div className="optimised_blue opt_box_security">
                  <h4>Seamless Integration</h4>   
                  <p>Our payment gateway integrates effortlessly with popular eCommerce platforms such as Shopify, WooCommerce, Magento, and more, ensuring a smooth setup process.</p>
                </div> 
                <div className="optimised_blue opt_box_security">
                  <h4>Enhanced Security</h4>   
                  <p>Leverage blockchain technology to ensure secure and transparent transactions, reducing the risk of fraud and chargebacks.</p>
                </div>    
              </div>
           </div>  
           <div className="row mt-25_opt">
              <div className="col-md-6">
                <div className="optimised_blue opt_box_security">
                  <h4>Instant Payment Processing</h4>   
                  <p>Benefit from real-time transaction processing, providing your customers with quick and efficient service.</p>
                </div> 
                <div className="optimised_blue opt_box_security">
                  <h4>Detailed Reporting and Analytics</h4>   
                  <p>Access comprehensive transaction reports and analytics to track sales, manage your finances, and gain insights into customer behavior.</p>
                </div>    
              </div>
              <div className="col-md-6 order-xs-first">
                <div className="optimised_blue">
                  <h4>Multi-Currency Support</h4>   
                  <p>Our system supports multiple cryptocurrencies and fiat currencies, offering flexibility and convenience for both you and your customers.</p>
                  <img src={optImage} alt="" />
                </div>      
              </div>
           </div>           
        </div>
      </section>


      
      <section className="key-reason-section unleashing_crypto-h">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>What You Get from Our <span class="orange_gredient_color_text">  Payment </span> Solution</h2>
           </div> 

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Expand Your Customer Base</h4>
                <p>Accepting cryptocurrency payments can attract tech-savvy customers and international buyers who prefer digital currencies</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Increase Sales</h4>
                <p>Offer more payment options to reduce cart abandonment and boost overall sales.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Lower Transaction Fees</h4>
                <p>Enjoy lower processing fees compared to traditional payment methods, saving your business money.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Enhanced Customer Experience</h4>
                <p>Provide a modern and convenient payment experience, improving customer satisfaction and loyalty.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Future-Proof Your Business </h4>
                <p>Embrace the future of payments with our innovative solutions, ensuring your business remains competitive and relevant.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Expand Your Customer Base</h4>
                    <p>Accepting cryptocurrency payments can attract tech-savvy customers and international buyers who prefer digital currencies</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Increase Sales</h4>
                    <p>Offer more payment options to reduce cart abandonment and boost overall sales.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Lower Transaction Fees</h4>
                    <p>Enjoy lower processing fees compared to traditional payment methods, saving your business money.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Enhanced Customer Experience</h4>
                    <p>Provide a modern and convenient payment experience, improving customer satisfaction and loyalty.</p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Future-Proof Your Business</h4>
                    <p>Embrace the future of payments with our innovative solutions, ensuring your business remains competitive and relevant.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="tertiaryButton">Join with us</a></div>
        </div>
      </section>


      <section  className="how-it-works"  style={howitWork}>
        <div className="container">
            <div className="row align-items-center mb-30-how">
                <div className="col-md-6">
                   <div className="how-it-bannerContent">  
                        <h2>How It Works</h2>
                        <p>When a customer selects cryptocurrency as their payment method at checkout, our payment gateway processes the transaction in real-time. The cryptocurrency is received by us, and the equivalent amount in cash is transferred to your account, ensuring you receive payments in your preferred currency without dealing with the complexities of crypto conversion and volatility.</p>
                   </div>   
                </div>
                <div className="col-md-6 od-1-mb">
                   <div className="how-it-image">  
                        <img src={howitwork}  alt="Image" />
                   </div>   
                </div>
            </div> 
            <div className="row align-items-center">
                <div className="col-md-6 ">
                   <div className="how-it-image">  
                        <img src={howitwork}  alt="Image" />
                   </div>   
                </div>
                <div className="col-md-6 order-f-first">
                   <div className="how-it-bannerContent">  
                        <h2>Service Offerings</h2>
                        <p>Integrating cryptocurrency payments with our eCommerce payment solution is a strategic move that can transform your online business operations. Our solutions are designed to provide you with the flexibility, security, and efficiency you need to thrive in today’s digital economy. Contact us today to learn more about how our eCommerce payment solution can benefit your business and help you stay ahead of the competition.</p>
                   </div>   
                </div>
            </div>  
        </div>
      </section>


      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default Ecommerce;
