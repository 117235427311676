import { useEffect, useRef } from 'react';
import emailIcon from "../images/Cryptolabs-white-logo-1.png";

const CustomMarker = ({ map, position }) => {
  const markerRef = useRef();

  useEffect(() => {
    const div = document.createElement('div');
    div.className = 'mapAddress';
    div.innerHTML = `
      <img src="http://localhost:3010/static/media/Cryptolabs-white-logo-1.aee1ef1508634aba2b5497206443a825.svg" class="mw-100" alt="Customized Image"/>
      <h4>Cryptolabs OTC Pty Ltd</h4>
      <p>93-95 Queens Street Melbourne, 3000</p>
    `;

    const overlayView = new window.google.maps.OverlayView();

    overlayView.onAdd = () => {
      const panes = overlayView.getPanes();
      panes.overlayImage.appendChild(div);
    };

    overlayView.draw = () => {
      const projection = overlayView.getProjection();
      const positionPixel = projection.fromLatLngToDivPixel(position);

      // Center the div over the marker
      div.style.left = `${positionPixel.x - div.offsetWidth / 2}px`;
      div.style.top = `${positionPixel.y - div.offsetHeight - 50}px`;
    };

    overlayView.onRemove = () => {
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    };

    overlayView.setMap(map);

    markerRef.current = overlayView;
    return () => {
      overlayView.setMap(null);
    };
  }, [map, position]);

  return null;
};

export default CustomMarker;
