import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import optImage from "../images/opt-dash-img.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Business = () => {
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center personal_Banner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                    <span className="orange_gredient_color_text">Crypto</span>{" "} for
                    Business
                </h1>
                <p>
                    Are you a business owner? Let's integrate crypto solutions for acquiring digital assets and managing everyday transactions and operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section  className="optimised_trading">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Optimised Trading for <span class="orange_gredient_color_text">  Business </span></h2>
            <p>Our platform is tailored to meet the demands of business clients, offering efficient, secure, and transparent trading solutions. At CryptoLabs OTC, we understand the unique challenges businesses face in the crypto market, and we are committed to providing the tools and services needed to navigate this dynamic landscape with confidence. Leverage our expertise and comprehensive services to optimize your crypto trading operations and achieve your business objectives.</p>
           </div>  
           <div className="row optimize_business">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Best Market Rates</h4>   
                  <p>Access competitive rates through our extensive network of liquidity providers, ensuring you get the most value from your trades.</p>
                  <img src={optImage} alt="" />
                </div>      
              </div>
              <div className="col-md-6 optimised_blue_p_business">
                <div className="optimised_blue">
                  <h4>Efficient Trade Execution</h4>   
                  <p>Our streamlined onboarding gets you started swiftly, opening doors to the world of cryptocurrencies effortlessly.</p>
                </div> 
                <div className="optimised_blue">
                  <h4>Smooth Onboarding</h4>   
                  <p>Our streamlined onboarding gets you started swiftly, opening doors to the world of cryptocurrencies effortlessly.</p>
                </div>    
              </div>
           </div>   
        </div>
      </section>


      
      <section className="key-reason-section unleashing_crypto-h">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>Unleashing <span class="orange_gredient_color_text">  Crypto Power </span> for your Business</h2>
           </div> 

          <div className="row d-none d-md-flex">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Dedicated Account Management</h4>
                <p>Enjoy personalized service with dedicated account managers who understand your business needs.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Regulatory Adherence</h4>
                <p>Operate within a compliant framework, adhering to all necessary regulations and standards.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Robust Security</h4>
                <p>Benefit from top-tier security measures that protect your transactions and digital assets.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Privacy and Confidentiality</h4>
                <p>We prioritize the confidentiality of your business transactions and trading activities.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Tailored Solutions</h4>
                <p>  Customized trading solutions designed to meet the specific requirements of your business, from risk management to bulk transactions.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Market Intelligence</h4>
                <p> Access to expert market analysis and insights to inform your trading strategies and business decisions.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Dedicated Account Management</h4>
                    <p>Enjoy personalized service with dedicated account managers who understand your business needs.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Regulatory Adherence</h4>
                    <p>Operate within a compliant framework, adhering to all necessary regulations and standards.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Robust Security</h4>
                    <p>Benefit from top-tier security measures that protect your transactions and digital assets.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Privacy and Confidentiality</h4>
                    <p>We prioritize the confidentiality of your business transactions and trading activities.</p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Tailored Solutions</h4>
                    <p> Customized trading solutions designed to meet the specific requirements of your business, from risk management to bulk transactions.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Market Intelligence</h4>
                    <p> Access to expert market analysis and insights to inform your trading strategies and business decisions.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="tertiaryButton">Join with us</a></div>

        </div>
      </section>

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default Business;
