import React from "react";
import TableTab from "../compontent/TableTab";
import ElevateTradeTable from "../compontent/Elevate-trade-table";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import config from "../config";
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Fees = () => {
  console.log(registerURL);
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tradeSettledStyle = {
    backgroundImage: `url(${tradeSettledBg})`,
    backgroundRepeat: "no-repeat", // Adjust as needed
    backgroundSize: "280px", // Adjust as needed
    backgroundPosition: "right", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center text-center feesBanner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                  Lowest Fees Fastest{" "}
                  <span className="orange_gredient_color_text">Trades</span>{" "}
                </h1>
                <p>
                  Our fees are lower than competitors whilst also providing
                  faster settlement times and a higher probability of trades
                  being settled at your requested rate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" keyFeesWhite">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>
              Key Features of Our{" "}
              <span class="orange_gredient_color_text"> Fee </span> Structure
            </h2>
            <p>
              At CryptoLabs, we understand the importance of competitive pricing
              and transparency in maintaining trust and fostering long-term
              relationships with our clients. Our fee structure for eCommerce
              payment solutions is designed to be straightforward, affordable,
              and free from hidden charges, ensuring you know exactly what
              you're paying for and can manage your costs effectively.
            </p>
          </div>

          <TableTab />
        </div>
      </section>

      <section className="key-reason-section feesKeyFeatures">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>
              Key Features of Our{" "}
              <span class="orange_gredient_color_text"> Fee </span> Structure
            </h2>
            <p>
              In the digital age, offering diverse payment options is crucial
              for the success of any online business. Our eCommerce payment
              solution enables online businesses to accept cryptocurrency
              payments seamlessly and securely. By integrating our cutting-edge
              crypto payment gateway, you can cater to a broader customer base,
              enhance transaction security, and stay ahead of the competition in
              the ever-evolving eCommerce landscape.
            </p>
          </div>

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>Competitive Transaction Fees</h4>
                <p>
                  We offer some of the lowest transaction fees in the industry,
                  allowing you to maximize your profits while providing a
                  seamless payment experience for your customers.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>Flat Rate Processing</h4>
                <p>
                  Our processing fees are based on a flat rate, ensuring
                  consistency and predictability in your payment costs.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>No Hidden Charges</h4>
                <p>
                  {" "}
                  Transparency is at the core of our pricing model. We believe
                  in clear and upfront communication, so you won’t encounter any
                  unexpected fees or charges.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>Volume-Based Discounts</h4>
                <p>
                  {" "}
                  For businesses with high transaction volumes, we offer
                  attractive discounts, making our services even more
                  cost-effective as you grow.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>Zero Setup Fees </h4>
                <p>
                  There are no fees for setting up your eCommerce payment
                  solution, making it easy and affordable to start accepting
                  cryptocurrency payments.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon} alt="Image" />
                <h4>Low Maintenance Costs</h4>
                <p>
                  Our platform is designed for minimal maintenance, reducing the
                  ongoing costs associated with managing your payment system.
                </p>
              </div>
            </div>
          </div>

          <div className="d-md-none key-p-slider">
            <Slider {...settings}>
              <div>
                <div className="key_reason-boxes">
                  <h4>Competitive Transaction Fees</h4>
                  <p>
                    We offer some of the lowest transaction fees in the
                    industry, allowing you to maximize your profits while
                    providing a seamless payment experience for your customers.
                  </p>
                </div>
                <div className="key_reason-boxes">
                  <h4>Flat Rate Processing</h4>
                  <p>
                    Our processing fees are based on a flat rate, ensuring
                    consistency and predictability in your payment costs.
                  </p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                  <h4>No Hidden Charges</h4>
                  <p>
                    {" "}
                    Transparency is at the core of our pricing model. We believe
                    in clear and upfront communication, so you won’t encounter
                    any unexpected fees or charges.
                  </p>
                </div>
                <div className="key_reason-boxes">
                  <h4>Volume-Based Discounts</h4>
                  <p>
                    {" "}
                    For businesses with high transaction volumes, we offer
                    attractive discounts, making our services even more
                    cost-effective as you grow.
                  </p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                  <h4>Zero Setup Fees</h4>
                  <p>
                    There are no fees for setting up your eCommerce payment
                    solution, making it easy and affordable to start accepting
                    cryptocurrency payments.
                  </p>
                </div>
                <div className="key_reason-boxes">
                  <h4>Low Maintenance Costs</h4>
                  <p>
                    Our platform is designed for minimal maintenance, reducing
                    the ongoing costs associated with managing your payment
                    system.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
          <div class="text-center mt-30">
            <a href="#" class="tertiaryButton" onClick={() => window.open(`${registerURL}`, "_blank")}>
              Join with us
            </a>
          </div>
        </div>
      </section>

      <section className="elevateCrypto">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>
              Elevate Your Trades with{" "}
              <span class="orange_gredient_color_text"> CryptoLabs </span>
            </h2>
          </div>
          <ElevateTradeTable />
        </div>
      </section>

      <section className="priceFees">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>
              Price Comparison with{" "}
              <span class="orange_gredient_color_text"> Competitors </span>
            </h2>
            <p>
              At CryptoLabs OTC, we pride ourselves on offering a seamless,
              efficient, and secure trading experience. Our platform is designed
              to cater to the specific needs of institutional investors,
              providing unparalleled access to liquidity and the best market
              rates. Whether you're looking to execute large trades swiftly or
              require personalized service to navigate the complexities of the
              crypto market, CryptoLabs OTC is here to ensure your success.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="priceBoxeInner text-center">
                <div className="priceBoxetop">
                  <h5>Deposit fees</h5>
                  <span>from</span>
                  <h3>
                    $0<span>%</span>
                  </h3>
                  <p>Down to 0.02%</p>
                </div>
                <div className="priceBoxeMiddle">
                  Free depost on amounts of AUD 100 and above
                </div>
                <p>
                  All bank deposits are completely free. Even deposits under
                  $100. Fees apply for PayPal, credit and debit card deposits.
                </p>
                <div className="text-center priceBtnsF">
                  <button type="button" class="secondary_btn">
                    Button
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="priceBoxeInner text-center">
                <div className="priceBoxetop">
                  <h5>Trade fees</h5>
                  <span>from</span>
                  <h3>
                    0.45<span>%</span>
                  </h3>
                  <p>Down to 0.01%</p>
                </div>
                <div className="priceBoxeMiddle">
                  Higher trade volumes result in lower fees
                </div>
                <p>
                  The calculated trade fee is based off of your 30 day trade
                  volume (in USDT), so the more you trade, the cheaper the fees
                  will be, going as low as $0.01 per $100 trade.
                </p>
                <div className="text-center priceBtnsF">
                  <button type="button" class=" primary_btn">
                    Button
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="priceBoxeInner text-center">
                <div className="priceBoxetop">
                  <h5>Withdrawal fees</h5>
                  <span>from</span>
                  <h3>
                    0.5<span>%</span>
                  </h3>
                  <p>Down to 0.02%</p>
                </div>
                <div className="priceBoxeMiddle">
                  Free AUD withdrawals of any amount!
                </div>
                <p>Fees may apply if you are liquidating crypto to cash.</p>
                <div className="text-center priceBtnsF">
                  <button type="button" class="secondary_btn">
                    Button
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feesFaq">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is CryptoLabs OTC?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  CryptoLabs OTC is an over-the-counter trading platform
                  designed for high-volume cryptocurrency transactions. We
                  provide faster settlement times and better customer service
                  for institutional investors by sourcing competitive rates
                  through various liquidity providers.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How does CryptoLabs OTC differ from regular exchanges?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Unlike traditional exchanges, CryptoLabs OTC offers
                  personalized service for large trades, ensuring minimal market
                  impact and providing better pricing through direct negotiation
                  with liquidity providers. This approach allows for more
                  efficient and cost-effective transactions.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What types of services does CryptoLabs OTC offer?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  CryptoLabs OTC offers a range of services including:
                  High-volume crypto trading POS terminal services for accepting
                  crypto payments in physical stores and online, ECommerce
                  payment solutions for online businesses to accept
                  cryptocurrency, and Automated invoice generation and financial
                  management tools
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Who can use CryptoLabs OTC?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Our platform is primarily aimed at institutional investors and
                  high-net-worth individuals who require large volume trades.
                  However, our POS and eCommerce solutions are available to
                  businesses of all sizes looking to accept cryptocurrency
                  payments.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">
                    Get in Touch
                  </span>{" "}
                  for Crypto Solutions
                </h2>
                <p>
                  Contact us today to learn how CryptoLabs OTC can transform
                  your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <select className="form-select" defaultValue={""}>
                          <option value="">Select your OTC trade amount</option>
                          <option value="0">Less than $10,000</option>
                          <option value="1">Less than $50,000</option>
                          <option value="2">Less than $100,000</option>
                          <option value="3">Less than $500,000</option>
                          <option value="3">More than $500,000</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button
                          type="button"
                          className="secondary_btn"
                          onClick={() =>
                            window.open(`${registerURL}`, "_blank")
                          }
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Fees;
