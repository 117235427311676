import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  logoImage1 from '../images/ETHEREUM.svg'; 
import  logoImage2 from '../images/LITECOIN.svg';
import  logoImage3 from '../images/DOGECOIN.svg';
import  logoImage4 from '../images/TETHER.svg';
import  logoImage5 from '../images/bitcoin.svg';


const LogoSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover: true,
        cssEase: "linear",
        arrows: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <section className='logoSlider'>
            <div className='container'>
                <div className='logoSliderWrapper'>
                    <Slider {...settings}>
                        <div className='clientLogoIn'>
                            <img src={logoImage1} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage2} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage3} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage4} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage5} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage1} alt='client logo' />
                        </div>
                        <div className='clientLogoIn'>
                            <img src={logoImage2} alt='client logo' />
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default LogoSlider;
