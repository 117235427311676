import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import optImage from "../images/mobile-circle.png";
import  onlyOtcDeskIcon from '../images/otc_desk-icon.svg';
import securityC from "../images/opt-dash-img.png";
import howmobile from "../images/w-v-ima.png";
import what_fa from "../images/what-2fa.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const TwoFa = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center text-center herobanner_two"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                Secure Your Account with  <span className="orange_gredient_color_text">2FA</span>{" "} 
                </h1>
                <p>
                  At CryptoLabs OTC, we require 2FA for all account actions, ensuring the highest level of security in the industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section  className="optimised_trading ensure_security">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Ensuring Security with  <span class="orange_gredient_color_text">  Two-Factor Authentication </span>  (2FA) </h2>
            <p>At CryptoLabs, security is our utmost priority. We are committed to providing the most secure platform for all your cryptocurrency transactions. To achieve this, we require two-factor authentication (2FA) for all logins and trades, ensuring that your account and assets are protected by the highest standards of security.</p>
           </div>  
        </div>
      </section>

      <section className="what-twofa d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="customizedAnalysisContent">
                <h2>
                What is{" "}
                  <span className="orange_gredient_color_text">
                  2FA
                  </span>?
                </h2>
                <p>
                  Two-Factor Authentication (2FA) is a security process that requires users to provide two different authentication factors to verify their identity. This adds an extra layer of protection beyond just a username and password, making it significantly harder for unauthorized individuals to access your account.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="customizedAnalysisImg">
                <img src={what_fa} className="w-100" alt="Customized Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section  className="optimised_trading secure-crypto-lbs how-two-work">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>How  <span class="orange_gredient_color_text">  2FA Works </span>   on Our Platform</h2>
          </div>  
           <div className="row">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Login Verification</h4>   
                  <p>When you log in to your CryptoLabs account, you will first enter your username and password. After this, you will be prompted to provide a second form of verification, such as a code sent to your mobile device or generated by an authenticator app.</p>
                </div>  
                <div className="optimised_blue">
                  <h4>Trade Certification</h4>   
                  <p>Every time you initiate a trade, our platform will require 2FA to ensure that the transaction is authorized by you. This prevents unauthorized trades and adds a layer of security to your trading activities.</p>
                </div>   
                <div className="optimised_blue">
                  <h4>Secure Authentication Methods</h4>   
                  <p>We support various 2FA methods, including SMS-based verification, authenticator apps like Google Authenticator or Authy, and hardware tokens for enhanced security.</p>
                </div>    
              </div>
              <div className="col-md-6">
                <div className="secure_img">
                    <img src={howmobile} alt="" className="w-100" />
                </div>   
              </div>
           </div> 
        </div>
      </section>


      
      <section className="key-reason-section advanced_transition benifits_work2fa" style={howitWork}> 
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2> <span class="orange_gredient_color_text">Benefits </span>   of Using 2FA</h2>
           </div> 

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Enhanced Security</h4>
                <p> 2FA significantly reduces the risk of unauthorized access by requiring two forms of verification.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Protection Against Phishing</h4>
                <p>Even if your password is compromised, the second factor of authentication helps protect your account from phishing attacks.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Peace of Mind</h4>
                <p>Knowing that your account and trades are secured by an additional layer of protection gives you confidence in the safety of your assets.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Enhanced Security</h4>
                    <p> 2FA significantly reduces the risk of unauthorized access by requiring two forms of verification.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Protection Against Phishing</h4>
                    <p>Even if your password is compromised, the second factor of authentication helps protect your account from phishing attacks.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Peace of Mind</h4>
                    <p>Knowing that your account and trades are secured by an additional layer of protection gives you confidence in the safety of your assets.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="primary_btn">Join with us</a></div>
        </div>
      </section>



      <section  className="optimised_trading our_commint-sec">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Our Commitment <span class="orange_gredient_color_text">  to Security </span></h2>
           </div>  
           <div className="row optimize_business">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Continuous Monitoring</h4>   
                  <p>Our security team continuously monitors for any suspicious activities and promptly addresses potential threats.</p>
                  <img src={securityC} alt="" />
                </div>      
              </div>
              <div className="col-md-6 optimised_blue_p_business">
                <div className="optimised_blue">
                  <h4>Regular Updates</h4>   
                  <p>We regularly update our security protocols to stay ahead of emerging threats and ensure our platform remains secure.</p>
                </div> 
                <div className="optimised_blue">
                  <h4>User Education</h4>   
                  <p>We provide resources and support to help you understand the importance of 2FA and how to use it effectively.</p>
                </div>    
              </div>
           </div>   
        </div>
      </section>


        <section className='onlyOtcDesk howsetup_fa'>
          <div className='container'>
              <div className='onlyOtcDeskHeading'>
                  <h2 className="text-left">How to Set Up <span className='orange_gredient_color_text'>2FA </span></h2>
                 
              </div>
              <div className='row d-md-flex'>
                  <div className='col-md-3'>
                      <div className='otcDeskBox'>
                          <div className='otcDeskBoxTop'>
                              <h3>1</h3>
                          </div>
                          <div className='otcDeskBoxContent'>
                             <img src={onlyOtcDeskIcon} alt='Otc Desk'/>
                             <h4>Login to Your Account</h4>
                              <p>Access your CryptoLabs account and navigate to the security settings.</p>
                          </div>
                      </div>
                  </div>
                  <div className='col-md-3'>
                      <div className='otcDeskBox'>
                          <div className='otcDeskBoxTop'>
                              <h3>2</h3>
                          </div>
                          <div className='otcDeskBoxContent'>
                              <img src={onlyOtcDeskIcon} alt='Same day'/>
                              <h4>Choose Your 2FA Method</h4>
                              <p>Select your preferred 2FA method, such as SMS or an authenticator app.</p>
                          </div>
                      </div>
                  </div>
                  <div className='col-md-3'>
                      <div className='otcDeskBox'>
                          <div className='otcDeskBoxTop'>
                              <h3>3</h3>
                          </div>
                          <div className='otcDeskBoxContent'>
                             <img src={onlyOtcDeskIcon} alt='Deep'/>
                             <h4>Follow the Setup Instructions</h4>
                              <p>Follow the on-screen instructions to complete the setup by scanning a QR code or entering a code.</p>
                          </div>
                      </div>
                  </div>
                  <div className='col-md-3'>
                      <div className='otcDeskBox'>
                          <div className='otcDeskBoxTop'>
                              <h3>4</h3>
                          </div>
                          <div className='otcDeskBoxContent'>
                             <img src={onlyOtcDeskIcon} alt='Smooth'/>
                             <h4>Confirm Activation</h4>
                              <p>Once setup is complete, confirm the activation to start using 2FA for all future logins and trades.</p>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </section>

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default TwoFa;
