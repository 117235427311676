import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import optImage from "../images/mobile-circle.png";
import  onlyOtcDeskIcon from '../images/otc_desk-icon.svg';
import securityC from "../images/opt-dash-img.png";
import howmobile from "../images/w-v-ima.png";
import what_fa from "../images/what-2fa.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Billing = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center text-center herobanner_two billingBannerHero"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                  <span className="orange_gredient_color_text">Effortless</span>{" "}  Invoice Management
                </h1>
                <p>
                  Our seamless platform auto-generates invoices in PDF or CSV format for easy integration with accounting software like MYOB or Xero.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section  className="optimised_trading billing_intro">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Streamlined  <span class="orange_gredient_color_text">  Billing and Financial </span>  Management </h2>
            <p>Managing finances can be a complex and time-consuming task, but at CryptoLabs, we aim to simplify this process with our advanced billing solutions. Our platform offers automated invoice generation and a state-of-the-art financial management tool, designed to help you manage your finances efficiently and effortlessly. Whether you are an individual trader or a business, our tools are tailored to meet your needs and ensure you have complete control over your financial operations.</p>
           </div>  
        </div>
      </section>

      <section  className="optimised_trading billingBenefits">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2> <span class="orange_gredient_color_text"> Benefits </span> of <br/> Our Billing Services</h2>
            <p>In the rapidly evolving world of commerce, staying ahead means embracing innovative payment solutions. Our POS terminal services enable companies to accept cryptocurrency payments both at physical shop terminals and online stores, seamlessly integrating digital currency transactions into their existing systems. By offering this cutting-edge payment method, you can attract a broader customer base, enhance transaction security, and stay competitive in today's market.</p>
           </div>  
           <div className="row optimize_business">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Automated Invoice Generation</h4>   
                  <p> Save time and reduce errors with our automated invoice generation feature. Our system creates detailed and accurate invoices for all your transactions, ensuring you always have a clear record of your financial activities.</p>
                  <img src={securityC} alt="" />
                </div>      
              </div>
              <div className="col-md-6 optimised_blue_p_business">
                <div className="optimised_blue">
                  <h4>Real-Time Financial Tracking</h4>   
                  <p>Monitor your financial status in real-time with our comprehensive dashboard. Track your income, expenses, and account balances with ease, giving you a clear and up-to-date picture of your financial health.</p>
                </div> 
                <div className="optimised_blue">
                  <h4>Customizable Invoices</h4>   
                  <p>Personalize your invoices to reflect your brand. Add your logo, customize the layout, and include any specific details that are important for your business.</p>
                </div>    
              </div>
           </div>   
        </div>
      </section>
      
      <section className="key-reason-section unlockBusinesss" style={howitWork}> 
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>Unlock Your <span class="orange_gredient_color_text">Business Success </span>  with Our Tools</h2>
           </div> 

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Increased Efficiency</h4>
                <p> Automate routine tasks such as invoicing and expense tracking, freeing up your time to focus on more important aspects of your business.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Improved Accuracy</h4>
                <p> Reduce the risk of manual errors with automated processes and real-time data tracking.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Enhanced Financial Control</h4>
                <p>Gain complete control over your financial operations with comprehensive tools and detailed reports.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Better Decision Making</h4>
                <p>Use insights from our financial reports and analytics to make informed decisions about your business.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Simplified Compliance</h4>
                <p>Keep accurate records and generate necessary reports to ensure compliance with financial regulations.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Increased Efficiency</h4>
                    <p> Automate routine tasks such as invoicing and expense tracking, freeing up your time to focus on more important aspects of your business.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Improved Accuracy</h4>
                    <p> Reduce the risk of manual errors with automated processes and real-time data tracking.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Enhanced Financial Control</h4>
                    <p>Gain complete control over your financial operations with comprehensive tools and detailed reports.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Better Decision Making</h4>
                    <p>Use insights from our financial reports and analytics to make informed decisions about your business.</p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Simplified Compliance</h4>
                    <p>Keep accurate records and generate necessary reports to ensure compliance with financial regulations.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" class="primary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>Join with us</a></div>
        </div>
      </section>

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default Billing;
