import React, { useRef, useState, useEffect } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import CustomMarker from './CustomMarker';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const center = {
  lat: -37.81628893259632,
  lng: 144.9610182648892,
};

const MapComponent = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDJV2u0TriFko96-IGtnfCks3NlfGvavgU',
    libraries,
  });

  const mapRef = useRef();
  const [map, setMap] = useState(null);

  const onMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
    setMap(mapInstance);

    const marker = new window.google.maps.Marker({
      position: center,
      map: mapInstance,
    });

    // Move marker to demonstrate movement
    setTimeout(() => {
      const newLatLng = new window.google.maps.LatLng(-37.81628893259632, 144.9610182648892);
      marker.setPosition(newLatLng);
      mapInstance.panTo(newLatLng);
    }, 1500);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={20}
      center={center}
      onLoad={onMapLoad}
    >
      {map && <CustomMarker map={map} position={center} />}
    </GoogleMap>
  );
};

export default MapComponent;
