import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./common-compontent/Header";
import Footer from "./common-compontent/Footer";
import Home from "./pages/Home";
import Personal from "./pages/Personal";
import Business from "./pages/business";
import Ecommerce from "./pages/Ecommerce";
import Otc from "./pages/Otc";
import TwoFa from "./pages/TwoFa";
import Aboutus from "./pages/about-us";
import Billing from "./pages/billing";
import Pos from "./pages/Pos";
import Fees from "./pages/Fees";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import OtpVerification from "./pages/Login/OtpVerification";
import KycForm from "./compontent/Kyc-form";
import NotFound from "./pages/NotFound";

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Header />{" "}
        {/* Ensure Header is outside Routes if it should be persistent */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/business" element={<Business />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/otc" element={<Otc />} />
          <Route path="/2fa" element={<TwoFa />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/pos" element={<Pos />} />
          <Route path="/fees" element={<Fees />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<OtpVerification />} />
          <Route path="/kyc" element={<KycForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;
