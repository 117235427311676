import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import optImage from "../images/mobile-circle.png";
import howitwork from "../images/how-it-w.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Otc = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center personal_Banner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                OTC for  <span className="orange_gredient_color_text">Large</span>{" "} Trades
                </h1>
                <p>
                    Our OTC services cater to high net worth clients and businesses, providing seamless and secure trades exceeding $100k USD with off-exchange liquidity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section  className="optimised_trading secure-crypto-lbs">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Secure your big trades with  <span class="orange_gredient_color_text">  CryptoLabs </span>  OTC</h2>
            <p>Our competitive solutions at CryptoLabs OTC are designed for high-volume cryptocurrency trades, offering exceptional prices, rapid execution, and unparalleled service. With our cutting-edge technology and extensive market expertise, we ensure your large-scale trades are executed seamlessly and efficiently, providing you with the best market rates and the speed you need to stay ahead in the dynamic world of cryptocurrency trading.</p>
           </div>  
           <div className="row">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Optimized for High-Volume Trades</h4>   
                  <p>Our platform handles large-scale transactions effortlessly, ensuring smooth and efficient execution.</p>
                </div>  
                <div className="optimised_blue">
                  <h4>Competitive Pricing</h4>   
                  <p>Access the best market rates through our extensive network of liquidity providers.</p>
                </div>   
                <div className="optimised_blue">
                  <h4>Ultra-Fast Execution</h4>   
                  <p>Experience rapid trade execution, minimizing latency and allowing you to capitalize on market opportunities.</p>
                </div>    
              </div>
              <div className="col-md-6">
                <div className="secure_img">
                    <img src={optImage} alt="" className="w-100" />
                </div>   
              </div>
           </div> 
        </div>
      </section>


      
      <section className="key-reason-section advanced_transition" style={howitWork}> 
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>Advanced <span class="orange_gredient_color_text">  OTC trading </span> for big transactions</h2>
           </div> 

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Superior Liquidity Access</h4>
                <p> Execute large trades without significant market impact, thanks to our deep liquidity pools.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Robust Security Measures</h4>
                <p>Measures: Top-tier security protocols protect your transactions and digital assets.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Tailored Trading Solutions</h4>
                <p>Customized solutions to meet the specific requirements of high-volume traders, from risk management to bulk transactions.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Advanced Trading Technology</h4>
                <p>Our user-friendly, advanced trading platform ensures a seamless and efficient trading experience.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Superior Liquidity Access</h4>
                    <p> Execute large trades without significant market impact, thanks to our deep liquidity pools.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Robust Security Measures</h4>
                    <p>Measures: Top-tier security protocols protect your transactions and digital assets.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Tailored Trading Solutions</h4>
                    <p>Customized solutions to meet the specific requirements of high-volume traders, from risk management to bulk transactions.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Advanced Trading Technology</h4>
                    <p>Our user-friendly, advanced trading platform ensures a seamless and efficient trading experience.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="primary_btn">Join with us</a></div>
        </div>
      </section>


      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default Otc;
