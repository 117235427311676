import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import keyIcon from "../images/Card_icon.svg";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Personal = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center personal_Banner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="bannerContent">
                <h1>
                    Explore <span className="orange_gredient_color_text">OTC</span>{" "}
                    with us
                </h1>
                <p>
                    At CryptoLabs OTC, we pride ourselves on offering a seamless, efficient, and secure trading experience. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section className="key-reason-section">
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2>Key reasons to use CryptoLabs <span class="orange_gredient_color_text"> OTC </span></h2>
            <p>At CryptoLabs OTC, we pride ourselves on offering a seamless, efficient, and secure trading experience. Our platform is designed to cater to the specific needs of institutional investors, providing unparalleled access to liquidity and the best market rates. Whether you're looking to execute large trades swiftly or require personalized service to navigate the complexities of the crypto market, CryptoLabs OTC is here to ensure your success.</p>
          </div> 

          <div className="row d-none d-md-flex">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Fast Settlement Times</h4>
                <p>Our platform ensures rapid settlement of trades, allowing you to access your funds quickly and efficiently.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Superior Customer Service</h4>
                <p>Dedicated account managers and a responsive support team are available to assist you 24/7.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Competitive Rates</h4>
                <p>Benefit from the best rates in the market through our extensive network of liquidity providers.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>High Liquidity</h4>
                <p>Execute large trades without significant market impact, thanks to our deep liquidity pools.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Security and Trust</h4>
                <p> Our state-of-the-art security measures ensure that your transactions and assets are always protected.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Customized Trading Solutions</h4>
                <p> Tailor-made solutions to meet your unique trading needs, from hedging strategies to large block trades.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Fast Settlement Times</h4>
                    <p>Our platform ensures rapid settlement of trades, allowing you to access your funds quickly and efficiently.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Superior Customer Service</h4>
                    <p>Dedicated account managers and a responsive support team are available to assist you 24/7.</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Competitive Rates</h4>
                    <p>Benefit from the best rates in the market through our extensive network of liquidity providers.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>High Liquidity</h4>
                    <p>Execute large trades without significant market impact, thanks to our deep liquidity pools.</p>
                </div>
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Security and Trust</h4>
                    <p> Our state-of-the-art security measures ensure that your transactions and assets are always protected.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Customized Trading Solutions</h4>
                    <p> Tailor-made solutions to meet your unique trading needs, from hedging strategies to large block trades.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="tertiaryButton">Join with us</a></div>

        </div>
      </section>




      <section className="experienced-section personal_exp-btn">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h4>Experience the CryptoLabs OTC difference and elevate your institutional trading strategy today.</h4>
            </div>
            <div className="col-md-4 text-end">
              <a href="#" className="primary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                Create an Account
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Personal;
