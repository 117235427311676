// src/SignupForm.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const ForgotPassword = ({ setPageType }) => {
  const navigate = useNavigate();
  return (
    <section className="signUpMain">
      <Helmet>
        <title>Crypto Labs - Forgot password</title>
      </Helmet>
      
      <div className="container">
        <div className="signUpBox loginForm forgotPwdF">
          <div className="signUpBoxHeader">
            <h3>Forgot Password?</h3>
            <p>No Worries, we’ll send you reset instructions.</p>
          </div>
          <div className="signUpForm">
            <form>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="usermail"
                  name="usermail"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <button type="submit" className="primary_btn">
                Reset Password
              </button>
              <div className="buttonBtmText forgotPsBtns">
                <p className="text-center">
                  New to Crypto Labs?{" "}
                  <a href="#" onClick={() => navigate("/otp")}>
                    Check OTP
                  </a>
                </p>
                <p className="text-center backToLoginBtn">
                  <a
                    href="#"
                    onClick={(e) => {
                      setPageType("loginpage");
                    }}
                  > 
                    {" "}
                    Back to login
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
