import React from 'react';
import Slider from "react-slick";
import tableLogo from "../images/Cryptolabs-white-logoTable.png";
import etherum from "../images/etherum.svg";
import TickImg from "../images/Tick.svg";
import flipLogo from "../images/flip-slide.svg";
import polygonLogo from "../images/polygon.svg";
import CloseMark from "../images/close-mark.svg";

const ElevateTradeTable = () => {
    const settings = {

    };

    return (

        <div className="table-responsive">
            <table className="table text-center"> 
            <thead>
                <tr>
                <th>Features</th>
                <th width="20%"><img src={tableLogo}  alt="Image" /></th>
                <th width="15%">CoinSpot</th>
                <th width="15%">BTC Markets</th>
                <th width="15%">OKX</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mobile App</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Instant deposits & withdrawals</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Trading fees</td>
                    <td>0.45%-0.01%</td>
                    <td>1.00% - 0.10%</td>
                    <td>0.85% - 0.10%</td>
                    <td>0.5% - 0.02%</td>
                </tr>
                <tr>
                    <td>Daily Deposit Limit</td>
                    <td>Unlimited</td>
                    <td>$100k</td>
                    <td>$10k</td>
                    <td>$1,000,000</td>
                </tr>
                <tr>
                    <td>Personal accounts	
                    </td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Business Accounts</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Trust Accounts</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Multi currency trading and settlement (AUD,USD,CNY,NZD,SGD,JPY)</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>PayPal Funding</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>Personal Account Manager</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                </tr>
                <tr>
                    <td>24/7 OTC Desk</td>
                    <td><img src={TickImg}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                    <td><img src={CloseMark}  alt="Image" /></td>
                </tr>
                
            </tbody>
            </table>    
        </div> 
  
    );
}

export default ElevateTradeTable;
