import React from 'react';
import Slider from "react-slick";
import  onlyOtcDeskIcon from '../images/otc_desk-icon.svg';
import  onlyOtcDeskImg from '../images/otc-24.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import config from '../config'; 
const webappUrl = config.webappUrl;
const registerURL = `${webappUrl}/register`;
const OtcSlider = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768, // Adjust the breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return (
        <section>
            <section className='onlyOtcDesk'>
                <div className='container'>
                    <div className='onlyOtcDeskHeading'>
                        <h2>The Only <span className='orange_gredient_color_text'>OTC Desk </span> In Australia that's <img src={onlyOtcDeskImg} alt=""/></h2>
                        <p>Have an important trade that just cannot wait until the next business day? Just call up your dedicated Crypto Labs broker and your trade will be processed straight away.</p>
                    </div>
                    <div className='row d-none d-md-flex '>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='Otc Desk'/>
                                    <h3>Low fees</h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>We minimize your costs with competitive fees, so more of your money stays invested in your ventures.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='Same day'/>
                                    <h3>Same Day settlements</h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>Experience efficient capital management with our same-day settlement service, ensuring your liquidity never falters.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='Deep'/>
                                    <h3>Deep Liquidity </h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>Benefit from our deep liquidity pool that ensures your large volume trades are executed smoothly and without delay.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='Smooth'/>
                                    <h3>Smooth Onboarding </h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>Our streamlined onboarding gets you started swiftly, opening doors to the world of cryptocurrencies effortlessly.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='On call'/>
                                    <h3>On Call 24/7</h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>Our experts are on hand 24/7, ready to assist with any inquiries to keep your operations running smoothly.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='otcDeskBox'>
                                <div className='otcDeskBoxTop'>
                                    <img src={onlyOtcDeskIcon} alt='Currencies'/>
                                    <h3>100 + Currencies</h3>
                                </div>
                                <div className='otcDeskBoxContent'>
                                    <p>Trade with ease in over 100 currencies, broadening your reach and opportunities in the global market.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-md-none'>
                        <Slider {...settings}>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='Otc Desk'/>
                                        <h3>Low fees</h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>We minimize your costs with competitive fees, so more of your money stays invested in your ventures.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='Same day'/>
                                        <h3>Same Day settlements</h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>Experience efficient capital management with our same-day settlement service, ensuring your liquidity never falters.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='Deep'/>
                                        <h3>Deep Liquidity </h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>Benefit from our deep liquidity pool that ensures your large volume trades are executed smoothly and without delay.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='Smooth'/>
                                        <h3>Smooth Onboarding </h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>Our streamlined onboarding gets you started swiftly, opening doors to the world of cryptocurrencies effortlessly.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='On call'/>
                                        <h3>On Call 24/7</h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>Our experts are on hand 24/7, ready to assist with any inquiries to keep your operations running smoothly.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='otcDeskBox'>
                                    <div className='otcDeskBoxTop'>
                                        <img src={onlyOtcDeskIcon} alt='Currencies'/>
                                        <h3>100 + Currencies</h3>
                                    </div>
                                    <div className='otcDeskBoxContent'>
                                        <p>Trade with ease in over 100 currencies, broadening your reach and opportunities in the global market.</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className='otcDeskBtn'>
                        <a href="#" className="primary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>Create an Account</a>
                    </div>
                </div>
            </section>
        </section>         
    );

}

export default OtcSlider;

