import React, { useEffect, useRef } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../images/Cryptolabs-white-logo-1.svg";
import bannerBgImage from "../images/Hero-Banner.png";
import aboutbanner_img from "../images/about_animation.png";
import aboutbanner_imgmobile from "../images/about_animation_mobile.png";
import servicesOffer from "../images/services-offering.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import howitwork from "../images/how-it-w.png";
import config from "../config";
import MapComponent from "../compontent/mymap";

const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;

const Aboutus = () => {
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "750px",
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const tradeSettledStyle = {
    backgroundImage: `url(${tradeSettledBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "280px",
    backgroundPosition: "right",
    width: "100%",
  };

  return (
    <main>

      <section
        className="heroBanner d-flex align-items-center aboutBanner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h1>
                  Countless reasons to trade Crypto with{" "}
                  <span className="orange_gredient_color_text">CryptoLabs</span>{" "}
                </h1>
                <p>
                  Access large trading volumes with minimal fees and maximum
                  security.
                </p>
                <div className="bannerContentBtn">
                  <a href="" onClick={() => window.open(`${registerURL}`, "_blank")} class="primary_btn">
                    Join with us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="aboutBanner_img">
                <img
                  className="d-none d-md-flex mw-100"
                  src={aboutbanner_img}
                  alt=""
                />
                <img
                  className="d-md-none mw-100"
                  src={aboutbanner_imgmobile}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>  

      <section className="key-reason-section streamlined_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="Streamlined_image">
                <img src={howitwork} className="w-100" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div class="onlyOtcDeskHeading">
                <h2>Streamlined Billing and Financial Management</h2>
                <p>
                  Managing finances can be a complex and time-consuming task,
                  but at CryptoLabs, we aim to simplify this process with our
                  advanced billing solutions. Our platform offers automated
                  invoice generation and a state-of-the-art financial management
                  tool, designed to help you manage your finances efficiently
                  and effortlessly. Whether you are an individual trader or a
                  business, our tools are tailored to meet your needs and ensure
                  you have complete control over your financial operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works automatedStream" style={howitWork}>
        <div className="container">
          <div className="row align-items-center mb-30-how">
            <div className="col-md-6">
              <div className="how-it-bannerContent">
                <h2>
                  Automated <span>Invoice</span> Generation
                </h2>
                <p>
                  Save time and reduce errors with our automated invoice
                  generation feature. Our system creates detailed and accurate
                  invoices for all your transactions, ensuring you always have a
                  clear record of your financial activities.
                </p>
              </div>
            </div>
            <div className="col-md-6 od-1-mb">
              <div className="how-it-image">
                <img src={howitwork} alt="Image" />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="how-it-image">
                <img src={howitwork} alt="Image" />
              </div>
            </div>
            <div className="col-md-6 order-f-first">
              <div className="how-it-bannerContent">
                <h2>
                  Streamlined{" "}
                  <span className="orange_gredient_color_text">
                    Billing and Financial{" "}
                  </span>{" "}
                  Management
                </h2>
                <p>
                  Managing finances can be a complex and time-consuming task,
                  but at CryptoLabs, we aim to simplify this process with our
                  advanced billing solutions. Our platform offers automated
                  invoice generation and a state-of-the-art financial management
                  tool, designed to help you manage your finances efficiently
                  and effortlessly. Whether you are an individual trader or a
                  business, our tools are tailored to meet your needs and ensure
                  you have complete control over your financial operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="what-twofa d-flex align-items-center serviveAbout">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="customizedAnalysisImg">
                <img
                  src={servicesOffer}
                  className="w-100"
                  alt="Customized Image"
                />
              </div>
            </div>
            <div className="col-md-6 order-f-first">
              <div className="customizedAnalysisContent">
                <h2>Conclusion</h2>
                <p>
                  Adopting cryptocurrency payments with our POS terminal
                  services is a strategic move that can transform your business
                  operations. Whether you run a physical store or an online
                  shop, our solutions are designed to provide you with the
                  flexibility, security, and efficiency you need to thrive in
                  today's digital economy. Contact us today to learn more about
                  how our POS terminal services can benefit your business and
                  help you stay ahead of the competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">
                    Get in Touch
                  </span>{" "}
                  for Crypto Solutions
                </h2>
                <p>
                  Contact us today to learn how CryptoLabs OTC can transform
                  your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button
                          type="button"
                          className="secondary_btn"
                          onClick={() =>
                            window.open(`${registerURL}`, "_blank")
                          }
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mapAboutFooter">
        <MapComponent></MapComponent>
      </section>
    </main>
  );
};

export default Aboutus;
