// src/SignupForm.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import userIcon from "../images/user_ic.svg";
import userIconWhite from "../images/user_ic-white.svg";
import cardIc from "../images/card_ic.svg";
import cardIcWhite from "../images/card-white.svg";
import securityIc from "../images/security-ic.svg";
import securityIcWhite from "../images/security-ic-white.svg";
import calIcon from "../images/cal_ic.svg";
import calIconWhite from "../images/cal-white.svg";
import successImg from "../images/verified_image.svg";


const KycForm = ({ setPageType }) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const nextStep = () => {
      if (step < 4) {
          setStep(step + 1);
      }
  };

  const prevStep = () => {
      if (step > 1) {
          setStep(step - 1);
      }
  };

  const renderStep = () => {
      switch (step) {
          case 1:
              return (
                  <fieldset>
                      <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <input type="text" className="form-control" placeholder="Lorem Ipsum Name"/>
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <input type="text" className="form-control" placeholder="Lorem Ipsum Name"/>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <input type="text" className="form-control"  placeholder="Lorem Ipsum Name"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <input type="text" className="form-control" placeholder="Lorem Ipsum Name"/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <select className="form-control form-select">
                                <option selected>Select an option</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <select className="form-control form-select">
                                <option selected>Select an option</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="form-group">
                                <label>Lorem Ipsum</label> 
                                <select className="form-control form-select">
                                <option selected>Select an option</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                      </div>
                      <div className="formStepFooter firstStepB">
                        <button type="button" className="nextBtn primary_btn" onClick={nextStep}>Next Step</button>
                      </div>
                  </fieldset>
              );
          case 2:
              return (
                  <fieldset>
                     <div className="formCustomRadio">
                        <p>Lorem Ipsum</p>
                        <div className="row">
                            <div className="col-md-3  col-6">
                                <div className="customRadio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                    <label className="form-check-label" for="flexRadioDefault1">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customRadio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                    <label className="form-check-label" for="flexRadioDefault2">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customRadio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                    <label className="form-check-label" for="flexRadioDefault3">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customRadio">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                    <label className="form-check-label" for="flexRadioDefault4">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formCheckB">
                        <p>Lorem Ipsum</p>
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <div className="customCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxDefault1"/>
                                    <label className="form-check-label" for="flexCheckboxDefault1">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxDefault2"/>
                                    <label className="form-check-label" for="flexCheckboxDefault2">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxDefault3"/>
                                    <label className="form-check-label" for="flexCheckboxDefault3">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxDefault4"/>
                                    <label className="form-check-label" for="flexCheckboxDefault4">
                                        Option 1
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-3 col-6">
                                <div className="customImgCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxUser1"/>
                                    <label className="form-check-label" for="flexCheckboxUser1">
                                        <span>
                                            <img className="iconStay" src={userIconWhite}  alt="" />
                                            <img className="iconHOver" src={userIcon}  alt="" />
                                        </span>
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customImgCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxUser2"/>
                                    <label className="form-check-label" for="flexCheckboxUser2">
                                        <span>
                                            <img className="iconStay" src={cardIc}  alt="" />
                                            <img className="iconHOver" src={cardIcWhite}  alt="" />
                                        </span>
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customImgCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxUser3"/>
                                    <label className="form-check-label" for="flexCheckboxUser3">
                                        <span>
                                            <img className="iconStay" src={securityIc}  alt="" />
                                            <img className="iconHOver" src={securityIcWhite}  alt="" />
                                        </span>
                                        Option 1
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-3 col-6">
                                <div className="customImgCheckbox">
                                    <input className="form-check-input" type="checkbox"  id="flexCheckboxUser4"/>
                                    <label className="form-check-label" for="flexCheckboxUser4">
                                        <span>
                                            <img className="iconStay" src={calIcon}  alt="" />
                                            <img className="iconHOver" src={calIconWhite}  alt="" />
                                        </span>
                                        Option 1
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="formStepFooter">
                        <button type="button" className="prevBtn secondary_btn" onClick={prevStep}>Previous</button>
                        <button type="button" className="nextBtn primary_btn" onClick={nextStep}>Next Step</button>
                    </div>
                  </fieldset>
              );
          case 3:
              return (
                  <fieldset>
                      <div className="form-card">
                          <h2 className="fs-title">Test</h2>
                      </div>
                      <div className="formStepFooter">
                        <button type="button" className="prevBtn secondary_btn" onClick={prevStep}>Previous</button>
                        <button type="button" className="nextBtn primary_btn" onClick={nextStep}>Next Step</button>
                    </div>
                  </fieldset>
              );
          case 4:
              return (
                  <fieldset>
                      <div className="form-card">
                          <div className="successCongImg text-center">
                            <img src={successImg} alt="" />
                            <h3>Congratulations! </h3>
                            <p>Your account is under review. We will notify you via email within 24-48 hours. Meanwhile, explore our services and get familiar with your dashboard.</p>
                          </div>
                      </div>
                      <div className="formStepFooter text-center">
                        <button type="button" className=" secondary_btn">Dashboard</button>
                        <button type="button" className=" primary_btn">Continue</button>
                    </div>
                  </fieldset>
              );
          default:
              return null;
      }
  };

  const getClass = (index) => {
      if (step > index) return 'complete';
      if (step === index) return 'active';
      return '';
  };



  return (
    <section className="signUpMain">
      <Helmet>
        <title>Crypto Labs - Kyc</title>
      </Helmet>
      
      <div className="container">
        <div className="signUpBox kycWrapperForm">
          <div className="signUpBoxHeader">
            <h3>Verify Your Account</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio.</p>
          </div>
          <div className="signUpForm">
            
        <form id="msform">
            {/* progressbar */}
            <ul id="progressbar">
                <li className={getClass(1)}>
                    <span></span>
                    <strong>Loremer Ipsum</strong>
                </li>
                <li className={getClass(2)}>
                    <span></span>
                    <strong>Loremer Ipsum</strong>
                </li>
                <li className={getClass(3)}>
                    <span></span>
                    <strong>Loremer Ipsum</strong>
                </li>
                <li className={getClass(4)}>
                    <span></span>
                    <strong>Loremer Ipsum</strong>
                </li>
            </ul>
            {/* fieldsets */}
            {renderStep()}
        </form>

          </div>
        </div>
      </div>
    </section>
  );
};

export default KycForm;
