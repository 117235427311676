import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../images/crypto-lab-blue-logo.svg";
import linkedin from "../images/linkedin.svg";
import fb from "../images/fb.svg";
import twitter from "../images/twitter.svg";
import insta from "../images/insta.svg";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSection, setOpenSection] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const pagestList = ["/login", "/register", "/otp", "/emailverification", "/kyc", "/email-confirmation"];
  const shouldHideFooter = pagestList.includes(location.pathname);

  return (
    <>
      {!shouldHideFooter ? (
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="logo">
                  <img src={logo} alt="Footer Logo" />
                </div>
                <div className="social-icon d-none-mobile">
                  <img src={linkedin} alt="LinkedIn" />
                  <img src={fb} alt="Facebook" />
                  <img src={insta} alt="Instagram" />
                  <img src={twitter} alt="Twitter" />
                </div>
              </div>
              <div
                className="col-md-2 mobileAcc"
                onClick={() => toggleSection("trending")}
              >
                <div className="plusMinus-ic">
                  <span></span>
                  <span></span>
                </div>
                <h3>Trending</h3>
                <nav
                  className={`nav ${openSection === "trending" ? "open" : ""}`}
                >
                  <ul className="menu">
                    <li>
                      <a onClick={() => navigate("/personal")}>Personal</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/business")}>Business</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/otc")}>OTC</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/")}>Family Planning</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/pos")}>POS Systems</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/ecommerce")}>E-Commerce</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/fees")}>Fees</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div
                className="col-md-2 mobileAcc"
                onClick={() => toggleSection("account")}
              >
                <div className="plusMinus-ic">
                  <span></span>
                  <span></span>
                </div>
                <h3>Account</h3>
                <nav
                  className={`nav ${openSection === "account" ? "open" : ""}`}
                >
                  <ul className="menu">
                    <li>
                      <a href="#">Register</a>
                    </li>
                    <li>
                      <a href="#">Login</a>
                    </li>
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">Funding</a>
                    </li>
                    <li>
                      <a href="#">Reset Password</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/2fa")}  >2FA</a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/billing")}>Billing</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div
                className="col-md-2 mobileAcc"
                onClick={() => toggleSection("about")}
              >
                <div className="plusMinus-ic">
                  <span></span>
                  <span></span>
                </div>
                <h3>About</h3>
                <nav className={`nav ${openSection === "about" ? "open" : ""}`}>
                  <ul className="menu">
                    <li>
                      <a onClick={() => navigate("/about")}>About Crypto Labs</a>
                    </li>
                    <li>
                      <a href="#">FAQ’s</a>
                    </li>
                    <li>
                      <a href="#">Media Enquiries</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div
                className="col-md-3 mobileAcc footerContact"
                onClick={() => toggleSection("contact")}
              >
                <div className="plusMinus-ic">
                  <span></span>
                  <span></span>
                </div>
                <h3>Contact</h3>
                <nav
                  className={`nav ${openSection === "contact" ? "open" : ""}`}
                >
                  <ul className="menu">
                    <li>
                      <a href="mailto:hello@cryptolabsotc.com">
                        hello@cryptolabsotc.com
                      </a>
                    </li>
                    <li>
                      <a href="#">93-95 Queen Street Melbourne, 3000</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="social-icon d-block-mobile">
                <img src={linkedin} alt="LinkedIn" />
                <img src={fb} alt="Facebook" />
                <img src={insta} alt="Instagram" />
                <img src={twitter} alt="Twitter" />
              </div>
            </div>
            <div className="footer-text">
              <p>
                Cryptolabs OTC Pty Ltd (85 664 950 672) (trading as "Cryptolabs
                OTC") operates a digital currency exchange registered with
                AUSTRAC. For more details, please review our
                Terms of Service and Privacy Policy.
              </p>
              <p>
                Cryptolabs OTC offers this information solely for your knowledge
                and does not advise on crypto transactions or trading
                strategies. We maintain a neutral stance on the market prices of
                cryptoassets. Be aware that the crypto sector can be
                unregulated, posing a risk of fund losses, and lacks certain
                governmental protections. Any profits from cryptoassets could
                incur taxes, so professional tax advice is advisable. Note that
                some of our services may not be accessible in all areas due to
                regulatory restrictions.
              </p>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
