// src/SignupForm.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginPage from "./Login/LoginPage";
import ForgotPassword from "./Login/ForgotPassword";
import OtpVerification from "./Login/OtpVerification";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const LoginForm = () => {
  const [pageType, setPageType] = useState("loginpage");
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Crypto Labs - Login</title>
      </Helmet>
      {pageType == "loginpage" ? <LoginPage setPageType={setPageType} /> : null}
      {pageType == "forgotpasswordpage" ? (
        <ForgotPassword setPageType={setPageType} />
      ) : null}
      {pageType == "otppage" ? <OtpVerification setPageType={setPageType} /> : null}
      </HelmetProvider>
    </>
  );
};

export default LoginForm;
