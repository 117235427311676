import React from "react";
import LogoSlider from "../compontent/Client-logo-slider";
import OtcSlider from "../compontent/Otc-desk-slider";
import bannerBgImage from "../images/Hero-Banner.png";
import bannerImage from "../images/Hero-Image.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import guranteeImage from "../images/guranteed-im.png";
import customizedAnalysis from "../images/customized-hg.png";
import moneyImage from "../images/money-image.png";
import account from "../images/account.svg";
import funds from "../images/funds.svg";
import investing from "../images/investing.svg";
import createAccount from "../images/create-account-icon.svg";
import depositFunds from "../images/deposite-fund.svg";
import beginInvesting from "../images/beign-icon.svg";
import { useNavigate } from "react-router-dom";
import config from '../config';
const webappUrl = config.webappUrl;
const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Home = () => {
  console.log(registerURL)
  const navigate = useNavigate();
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "852px", // Adjust as needed
  };

  const tradeSettledStyle = {
    backgroundImage: `url(${tradeSettledBg})`,
    backgroundRepeat: "no-repeat", // Adjust as needed
    backgroundSize: "280px", // Adjust as needed
    backgroundPosition: "right", // Adjust as needed
    width: "100%", // Adjust as needed
  };
  return (
    <main>
      <section
        className="heroBanner d-flex align-items-center"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="bannerContent">
                <h1>
                  Secure <span className="orange_gredient_color_text">OTC</span>{" "}
                  Trading
                </h1>
                <p>
                  We have various liquidity providers all around the world
                  enables us to fulfil your OTC trade, regardless of the volume.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <img src={bannerImage} className="w-100" alt="Banner Image" />
            </div>
          </div>
        </div>
      </section>

      <LogoSlider />

      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  Trades settled on the{" "}
                  <span className="orange_gredient_color_text">same day</span>
                </h2>
                <div className="guarnteed_image">
                  <img src={guranteeImage} alt="Trade Image" />
                </div>
                <p>
                  We have various liquidity providers all around the world
                  enables us to fulfil your OTC trade, regardless of the volume.
                </p>
                <div className="viewMarketBtn">
                  <a href="#" className="tertiaryButton">
                    View Markets
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="customizedAnalysis d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="customizedAnalysisContent">
                <h2>
                  Customized{" "}
                  <span className="orange_gredient_color_text">
                    Analysis tools
                  </span>
                </h2>
                <p>
                  We have various liquidity providers all around the world
                  enables us to fulfil your OTC trade, regardless of the volume.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="customizedAnalysisImg">
                <img
                  src={customizedAnalysis}
                  className="w-100"
                  alt="Customized Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OtcSlider />

      <section className="moneyAccess">
        <div className="container">
          <h2>
            Your money, accessible{" "}
            <span className="orange_gredient_color_text">
              Anytime, Anywhere{" "}
            </span>{" "}
          </h2>
          <div className="moneyAccessImage">
            <img src={moneyImage} className="w-100" alt="client logo" />
          </div>
        </div>
      </section>

      <section className="invest-section">
        <div className="onlyOtcDeskHeading">
          <h2>
            How to <span className="orange_gredient_color_text">invest </span>{" "}
            your funds{" "}
          </h2>
          <p>Our KYC is fast, and our trades fulfil faster.</p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="invest-main text-center">
                <div className="investArrowIcon">
                  <img src={createAccount} alt="Icon" />
                </div>
                <img src={account} alt="Icon" />
                <h3>Create Account </h3>
                <p>
                  Buy, sell and track your crypto portfolio on your desktop or
                  mobile device.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="invest-main text-center">
                <div className="investArrowIcon">
                  <img src={depositFunds} alt="Icon" />
                </div>
                <img src={funds} alt="Icon" />
                <h3>Deposit Funds</h3>
                <p>
                  PayID, PayNow, bank transfer, PayPal, debit, credit cards and
                  more are accepted.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="invest-main text-center">
                <div className="investArrowIcon">
                  <img src={beginInvesting} alt="Icon" />
                </div>
                <img src={investing} alt="Icon" />
                <h3>Begin Investing</h3>
                <p>
                  Call up our 24/7 OTC desk and have you trade fulfiled on the
                  same business day!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="experienced-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Ready to connect with our experienced brokers?</h2>
            </div>
            <div className="col-md-6 text-end">
              <a href="#" className="primary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                Create an Account
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
