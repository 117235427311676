import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import bannerBgImage from "../images/Hero-Banner.png";
import howbg from "../images/Hero-Banner.png";
import tradeSettledBg from "../images/trade-settled-bg.png";
import keyIcon from "../images/Card_icon.svg";
import businessImgPos from "../images/poc-img.webp";
import optImage from "../images/mobile-circle.png";
import  onlyOtcDeskIcon from '../images/otc_desk-icon.svg';
import businessImgPosMobile from "../images/mobile-m-business.webp";
import howmobile from "../images/intro-pos-img.webp";
import what_fa from "../images/what-2fa.png";
import config from '../config';
const webappUrl = config.webappUrl;

const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;
const Pos = () => {
  console.log(registerURL)
  const heroBannerStyle = {
    backgroundImage: `url(${bannerBgImage})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
    height: "750px", // Adjust as needed
  };

  const howitWork = {
    backgroundImage: `url(${howbg})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundPosition: "center", // Adjust as needed
    width: "100%", // Adjust as needed
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 768, // Adjust the breakpoint as needed
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const tradeSettledStyle = {
  backgroundImage: `url(${tradeSettledBg})`,
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundSize: "280px", // Adjust as needed
  backgroundPosition: "right", // Adjust as needed
  width: "100%", // Adjust as needed
};

  return (
    <main>
      <section
        className="heroBanner d-flex text-center businessPosBanner"
        style={heroBannerStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h1>
                Business  <span className="orange_gredient_color_text">POS</span>{" "} with Crypto 
                </h1>
                <p>
                Our POS terminal lets companies accept and make payments with crypto, allowing people to buy items like coffee and cars, while the merchant gets a bank transfer directly into their account.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="businessImgPos">
            <img src={businessImgPos} alt="" className="mw-100 d-none d-md-flex" />
            <img src={businessImgPosMobile} alt="" className="mw-100 d-md-none" />     
        </div>
      </section>



      <section  className="optimised_trading secure-crypto-lbs how-two-work posTerminialInt">
        <div className="container">
          <div class="onlyOtcDeskHeading text-center">
            <h2>Introducing Our  <span class="orange_gredient_color_text">  POS </span>   Terminal</h2>
            <p>In the rapidly evolving world of commerce, staying ahead means embracing innovative payment solutions. Our POS terminal services enable companies to accept cryptocurrency payments both at physical shop terminals and online stores, seamlessly integrating digital currency transactions into their existing systems. By offering this cutting-edge payment method, you can attract a broader customer base, enhance transaction security, and stay competitive in today's market.</p>
          </div>  
           <div className="row align-items-center">
              <div className="col-md-6">
                <div className="optimised_blue">
                  <h4>Seamless Integration</h4>   
                  <p>Our POS terminals integrate effortlessly with your existing payment systems, whether you operate a brick-and-mortar store or an online platform.</p>
                </div>  
                <div className="optimised_blue">
                  <h4>Real-Time Transactions</h4>   
                  <p>Utilize blockchain technology to ensure secure and transparent transactions, reducing the risk of fraud and chargebacks.</p>
                </div>   
                <div className="optimised_blue">
                  <h4>Comprehensive Crypto Support</h4>   
                  <p>Accept a wide range of cryptocurrencies including Bitcoin, Ethereum, Litecoin, and more, catering to the preferences of your diverse customer base.</p>
                </div>    
              </div>
              <div className="col-md-6">
                <div className="secure_img">
                    <img src={howmobile} alt="" className="w-100" />
                </div>   
              </div>
           </div> 
        </div>
      </section>


      <section className="what-twofa d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="customizedAnalysisContent">
                <h2>
                Cash Payments from{" "}
                  <span className="orange_gredient_color_text">
                  Crypto
                  </span> Transactions
                </h2>
                <p>
                One of the standout features of our POS terminal services is the ability for merchants to receive cash directly into their bank accounts, even though customers pay with cryptocurrency. When a customer pays with crypto, we handle the transaction by receiving the cryptocurrency and then transferring the equivalent amount in cash to the merchant's account. This process ensures that businesses can enjoy the benefits of accepting crypto payments without worrying about volatility or conversion complexities.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="customizedAnalysisImg">
                <img src={what_fa} className="w-100" alt="Customized Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      
      <section className="key-reason-section advanced_transition benifitsPosSec" style={howitWork}> 
        <div className="container">
          <div class="onlyOtcDeskHeading">
            <h2> Benefits of Using <br/>Our<span class="orange_gredient_color_text"> POS Terminal</span> Services  </h2>
           </div> 

          <div className="row d-none d-md-flex justify-content-center">
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Expand Your Customer Base</h4>
                <p> By accepting cryptocurrency payments, you can attract tech-savvy customers and international buyers who prefer digital currencies.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Increase Sales</h4>
                <p>Offer more payment options to reduce cart abandonment and boost overall sales.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Lower Transaction Fees</h4>
                <p>Enjoy lower processing fees compared to traditional payment methods, saving your business money.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Enhanced Customer Experience</h4>
                <p>Provide a modern and convenient payment experience, improving customer satisfaction and loyalty</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="key_reason-boxes">
                <img src={keyIcon}  alt="Image" />
                <h4>Future-Proof Your Business</h4>
                <p>Embrace the future of payments with our innovative POS solutions, ensuring your business remains competitive and relevant.</p>
              </div>
            </div>
          </div>

          <div className='d-md-none key-p-slider'>
            <Slider {...settings}>  
              <div>
                <div className="key_reason-boxes">
                    <h4>Enhanced Security</h4>
                    <p> 2FA significantly reduces the risk of unauthorized access by requiring two forms of verification.</p>
                </div>
                <div className="key_reason-boxes">
                    <h4>Enhanced Customer Experience</h4>
                    <p>Provide a modern and convenient payment experience, improving customer satisfaction and loyalty</p>
                </div>      
              </div>

              <div>
                <div className="key_reason-boxes">
                    <h4>Future-Proof Your Business</h4>
                    <p>Embrace the future of payments with our innovative POS solutions, ensuring your business remains competitive and relevant.</p>
                </div>
              </div>
            </Slider>
          </div>


          <div class="text-center mt-30"><a href="#" onClick={() => window.open(`${registerURL}`, "_blank")} class="primary_btn">Join with us</a></div>
        </div>
      </section>


      <section className="what-twofa d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="customizedAnalysisImg">
                <img src={what_fa} className="mw-100" alt="Customized Image"
                />
              </div>
            </div>
            <div className="col-md-6 order-f-first">
              <div className="customizedAnalysisContent">
                <h2>
                    Service Offerings
                </h2>
                <p>
                Integrating cryptocurrency payments with our eCommerce payment solution is a strategic move that can transform your online business operations. Our solutions are designed to provide you with the flexibility, security, and efficiency you need to thrive in today’s digital economy. Contact us today to learn more about how our eCommerce payment solution can benefit your business and help you stay ahead of the competition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section
        className="tradeSettled d-flex align-items-center"
        style={tradeSettledStyle}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="bannerContent">
                <h2>
                  <span className="orange_gredient_color_text">Get in Touch</span> for Crypto Solutions
                </h2>
                <p>
                Contact us today to learn how CryptoLabs OTC can transform your business with our tailored crypto solutions.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tradeForm">
                <form>
                  <h4>Trade Now!</h4>
                  <p>
                    Fill out the details below and a Crypto Labs representative
                    will be in touch in under 12 hours.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-field">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-field">
                      <select className="form-select" defaultValue={""}>
                          <option >Your OTC Trade Amount</option>
                          <option value="$0-$50K AUD">$0-$50K AUD</option>
                          <option value="$50K-$100K AUD">$50K-$100K AUD</option>
                          <option value="$100K-$250K AUD">$100K-$250K AUD</option>
                          <option value="More than $250K AUD">More than $250K AUD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                        <button type="submit" className=" primary_btn">
                          Get In Touch
                        </button>
                        <span className="orSeprate">Or</span>
                      </div>
                    </div> 
                    <div className="col-md-6 tabletFullWidth">
                      <div className="form-field moreSpace">
                      <button type="button" className="secondary_btn" onClick={() => window.open(`${registerURL}`, "_blank")}>
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </main>
  );
};

export default Pos;
