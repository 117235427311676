import React from "react";
import TabLeftBg from "../images/Hero-Banner.png";

const tabContent = [
  {
    title: "Spot Crypto",
    description:
      "Crypto Labs uses a maker-taker fee schedule with volume incentives based on your activity in the past 30 days.",
    details:
      "Our fee schedules are built to encourage traders to engage with the market and to drive maximum liquidity.",
    note: "",
    tableRows: [
      ["$0 - $10,000", "0.25%", "0.40%"],
      ["$10,001 - $50,000", "0.20%", "0.35%"],
      ["$50,001 - $100,000", "0.14%", "0.24%"],
      ["$100,001 - $250,000", "0.12%", "0.22%"],
      ["$250,001 - $500,000", "0.10%", "0.20%"],
      ["$500,001 - $1,000,000", "0.08%", "0.18%"],
      ["$1,000,001 - $2,500,000", "0.06%", "0.16%"],
      ["$2,500,001 - $5,000,000", "0.04%", "0.14%"],
      ["$5,000,001 - $10,000,000", "0.02%", "0.12%"],
      ["$10,000,000+", "0.00%", "0.10%"],
    ],
  },
  {
    title: "Stablecoin, Pegged Token & FX Pairs",
    description:
      "For Stablecoins, pegged tokens and FX pairs, the fee structures are lower to encourage deeper liquidity for our clients. All fee schedules in this category apply to stablecoins in the base currency, quote currency, or both.",
    details:
      "",
    note: "",
    tableRows: [
      ["$0 - $10,000", "0.20%", "0.30%"],
      ["$10,001 - $50,000", "0.16%", "0.24%"],
      ["$50,001 - $100,000", "0.12%", "0.20%"],
      ["$100,001 - $250,000", "0.10%", "0.18%"],
      ["$250,001 - $500,000", "0.08%", "0.16%"],
      ["$500,001 - $1,000,000", "0.06%", "0.14%"],
      ["$1,000,001 - $2,500,000", "0.04%", "0.12%"],
      ["$2,500,001 - $5,000,000", "0.02%", "0.10%"],
      ["$5,000,001 - $10,000,000", "0.01%", "0.08%"],
      ["$10,000,000+", "0.00%", "0.06%"],
    ],
  },
  
];

const TableTab = () => {
  return (
    <div className="tableWrapper">
      <div className="d-block d-md-none">
        <div className="accordion" id="accordionExample">
          {tabContent.map((tab, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className={`accordion-button ${
                    index === 0 ? "" : "collapsed"
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded={index === 0 ? "true" : "false"}
                  aria-controls={`collapse${index}`}
                >
                  {tab.title}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse ${
                  index === 0 ? "show" : ""
                }`}
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div
                    className="tabsLeftBg"
                    style={{
                      backgroundImage: `url(${TabLeftBg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <h4>{tab.description}</h4>
                    <p>{tab.details}</p>
                    <p>{tab.note}</p>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">30-Day Average USDT Volume</th>
                        <th width="20%">Maker</th>
                        <th width="20%">Taker</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tab.tableRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-none d-md-block">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {tabContent.map((tab, index) => (
              <button
                key={index}
                className={`nav-link ${index === 0 ? "active" : ""}`}
                id={`nav-${index}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#nav-${index}`}
                type="button"
                role="tab"
                aria-controls={`nav-${index}`}
                aria-selected={index === 0 ? "true" : "false"}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          {tabContent.map((tab, index) => (
            <div
              key={index}
              className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
              id={`nav-${index}`}
              role="tabpanel"
              aria-labelledby={`nav-${index}-tab`}
            >
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="tabsLeftBg"
                    style={{
                      backgroundImage: `url(${TabLeftBg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <h4>{tab.description}</h4>
                    <p>{tab.details}</p>
                    <p>{tab.note}</p>
                  </div>
                </div>
                <div className="col-md-9">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">30-Day Average USDT Volume</th>
                        <th width="20%">Maker</th>
                        <th width="20%">Taker</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tab.tableRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableTab;
