import React, { useState, useEffect } from "react";
import logo from "../images/crypto-lab-blue-logo.svg";
import humburgericon from "../images/humburger-menu.svg";
import closeIcon from "../images/close-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../config";
import { login } from "../../src/utils/auth";

const webappUrl = config.webappUrl;
const registerURL = `${webappUrl}/register`;
const loginURL = `${webappUrl}/login`;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(location.pathname);

  const handleMenuIconClick = () => {
    setIsMenuOpen(true);
  };

  const handleCloseIconClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("menuOpen");
    } else {
      document.body.classList.remove("menuOpen");
    }
  }, [isMenuOpen]);

  const pathsToHideMenu = ["/login", "/register", "/otp", "/emailverification", "/kyc", "/email-confirmation"];
  const shouldHideMenu = pathsToHideMenu.includes(location.pathname);

  const handleMenuItemClick = (path) => {
    setActiveMenuItem(path);
    setIsMenuOpen(false);
    navigate(path);
  };

  const handleLogoClick = () => {
    handleMenuItemClick("/");
  };

  return (
    <>
      {!shouldHideMenu ? (
        <header className="header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-3 col-8">
                <div className="logo d-flex">
                  <img src={logo} alt="Logo" onClick={handleLogoClick} />
                </div>
              </div>
              <div className="menuIcon d-lg-none">
                <button className="menuIcon" onClick={handleMenuIconClick}>
                  <img src={humburgericon} alt="Menu Icon" />
                </button>
              </div>
              <div className="col-md-6 d-none-mobile">
                <nav className="nav">
                  <ul className="menu justify-content-center">
                    
                    <li className={activeMenuItem === "/personal" ? "active" : ""}>
                      <a onClick={() => handleMenuItemClick("/personal")}>Personal</a>
                    </li>
                    <li className={activeMenuItem === "/business" ? "active" : ""}>
                      <a onClick={() => handleMenuItemClick("/business")}>Business</a>
                    </li>
                    <li className={activeMenuItem === "/ecommerce" ? "active" : ""}>
                      <a onClick={() => handleMenuItemClick("/ecommerce")}>Ecommerce</a>
                    </li>
                    <li className={activeMenuItem === "/otc" ? "active" : ""}>
                      <a onClick={() => handleMenuItemClick("/otc")}>OTC</a>
                    </li>
                    <li className={activeMenuItem === "/pos" ? "active" : ""}>
                      <a onClick={() => handleMenuItemClick("/pos")}>POS</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-md-3 d-none-mobile">
                <ul className="menuButton d-flex justify-content-end">
                  <li>
                    <button
                      type="button"
                      className="secondary_btn"
                      onClick={() => window.open(`${loginURL}`, "_blank")}
                    >
                      Sign In
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="primary_btn"
                      onClick={() => window.open(`${registerURL}`, "_blank")}
                    >
                      Register
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="MobileResponsive d-lg-none">
            <div className="row align-items-center justify-content-between text-end">
              <div className="col-md-5 col-10">
                <div className="logo d-flex">
                  <a onClick={handleLogoClick}>
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="col-2">
                <button className="closeIcon" onClick={handleCloseIconClick}>
                  <img src={closeIcon} alt="Close Icon" />
                </button>
              </div>
            </div>
            <ul className="menu">
              
              <li className={activeMenuItem === "/personal" ? "active" : ""}>
                <a onClick={() => handleMenuItemClick("/personal")}>Personal</a>
              </li>
              <li className={activeMenuItem === "/business" ? "active" : ""}>
                <a onClick={() => handleMenuItemClick("/business")}>Business</a>
              </li>
              <li className={activeMenuItem === "/ecommerce" ? "active" : ""}>
                <a onClick={() => handleMenuItemClick("/ecommerce")}>Ecommerce</a>
              </li>
              <li className={activeMenuItem === "/otc" ? "active" : ""}>
                <a onClick={() => handleMenuItemClick("/otc")}>OTC</a>
              </li>
              <li className={activeMenuItem === "/pos" ? "active" : ""}>
                <a onClick={() => handleMenuItemClick("/pos")}>POS</a>
              </li>
            </ul>
            <ul className="menuButton">
              <li>
                <button
                  type="button"
                  className="secondary_btn"
                  onClick={() => window.open(`${loginURL}`, "_blank")}
                >
                  Sign In
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="primary_btn"
                  onClick={() => window.open(`${registerURL}`, "_blank")}
                >
                  Register
                </button>
              </li>
            </ul>
          </div>
        </header>
      ) : (
        <header className="header">
          <div className="container">
            <div className="logo d-flex">
              <img src={logo} alt="Logo" onClick={handleLogoClick} />
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
